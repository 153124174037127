.page-help {
  padding-top: 60px;
  width: 90%;
  margin: auto;
  text-align: center;

  h2 {
    margin-bottom: 50px;
  }

  .MuiButton-root {
    margin-bottom: 20px;
  }
}