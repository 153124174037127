@import "src/assets/styles/fonts";
@import "src/assets/styles/colors";

.page-welcome {
  padding-top: 70px;

  p {
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 2.6rem;
    margin-bottom: 30px;
    font-family: $font-title;

    strong {
      font-size: 3rem;
    }
  }

  .name {
    color: $color-secondary;
  }

  .img {
    width: 90%;
    margin: auto;
  }
}