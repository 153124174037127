$color-primary: #578AD6;
$color-primary-light: #78a1de;
$color-primary-2: #456eab;
$color-secondary: #FF7F2D;
$color-muted: #aaaaaa;
$color-gray: #777777;
$color-white: #ffffff;
$color-gray-light: #efefef;

$color-success: #32712c;
$color-error: #aa1f2f;
$color-purple: #8B5CF6;
$color-pink: #EC4899;

$color-background-default: #f0f0f0;
